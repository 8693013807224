import React from 'react';

const Footer = (props) => {
    return (
        <div className='footer'>
            <div className='row mobileRtL'>
                <div className='col-md-6 copyRight'>
                  <p className='footerPtext'>©2024 Safesearchengine</p>
                </div>
                <div className='col-md-6' >
                    <ul className='footerMenu'>
                        <li><a href='/privacy'>Privacy</a></li>
                        <li><a href='/terms'>Terms</a></li>
                        <li><a href='/contact'>Contact</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Footer;